<template>
  <div :class="`input-wrap is-relative ${isError ? 'is-error' : ''}`">
    <div class="symbol has-text-weight-bold has-text-blue">$</div>
    <div
      class="input amount has-text-blue has-text-weight-semibold"
      @click="showNumericKeyboard"
      v-if="isMobile"
    >{{amount}}</div>
    <input
      class="input amount has-text-blue has-text-weight-semibold"
      v-model.trim="amount"
      placeholder="0.00"
      @keydown="onDesktopInput"
      @blur="formatInput"
      v-else
    >
    <div class="currency has-text-weight-bold">{{currency}}</div>
    <NumberKeyboard
      :show="showKeyboard"
      extra-key="."
      close-button-text="Close"
      @close="onClose"
      @input="onInput"
      @delete="onDelete"
    ></NumberKeyboard>
  </div>
</template>
<script>
import { mobileCheck } from '@utils/common-methods/common'
import { formatter } from '@utils/common-methods/numberFormatter'
import NumberKeyboard from 'vant/lib/number-keyboard'
import 'vant/lib/number-keyboard/style'

export default {
  components: {
    NumberKeyboard,
  },
  data() {
    return {
      amount: '',
      showKeyboard: false,
    }
  },
  props: {
    initialValue: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false
    },
    scrollAnchorId: {
      type: String,
      default: '',
    },
    pageId: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: 'CAD',
    },
  },
  watch: {
    initialValue: {
      handler(value) {
        if (Number(value)) this.amount = formatter(value)
      },
      immediate: true
    },
  },
  computed: {
    hasDot() {
      return this.amount.match(/\./g)
    },
    isMobile() {
      return mobileCheck()
    },
  },
  methods: {
    async showNumericKeyboard() {
      if (!this.isMobile) return
      document.getElementById(this.pageId).style['padding-bottom'] = '400px'
      await this.$nextTick()

      document.getElementById(this.scrollAnchorId).scrollIntoView()
      this.showKeyboard = true
      this.amount = this.formatToNumber(this.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    hideNumericKeyboard() {
      this.showKeyboard = false
    },
    formatInput() {
      if (!this.amount) return
      const stripped = this.formatToNumber(this.amount)
      this.amount = formatter(stripped)
    },
    formatToNumber(amount) {
      return Number(amount.replace(/,/g, ''))
    },
    deleteStartingZero() {
      if (this.amount === '0') this.amount = ''
    },
    onInput(input) {
      this.deleteStartingZero()
      const tooManyDots = this.hasDot && input === '.'
      const hasTwoDecimals = this.amount.match(/[\d,]*\.\d{2}/)
      if (tooManyDots || hasTwoDecimals) return
      if (input === '.') this.amount += input
      else {
        const newValue = this.amount.replace(/,/g, '') + input
        this.amount = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      this.$emit('onInput', this.formatToNumber(this.amount))
    },
    onDelete() {
      this.amount = this.amount.slice(0, -1).replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.$emit('onInput', this.formatToNumber(this.amount))
    },
    onClose() {
      document.getElementById(this.pageId).style['padding-bottom'] = ''
      this.formatInput()
      this.hideNumericKeyboard()
    },
    onDesktopInput(event) {
      event.preventDefault()
      const key = event.key
      if (key === 'Backspace') return this.onDelete()
      if (!this.validateInput(key)) return

      this.onInput(key)
    },
    validateInput(inputCharacter) {
      const regex = /^[0-9]*\.?[0-9]*$/
      return inputCharacter.match(regex)
    },
  },
}
</script>
<style lang="scss" scoped>
.input-wrap {
  &.is-error {
    .symbol {
      color: #C91717 !important;
    }
    .input {
      color: #C91717 !important;
      border-color: #C91717 !important;
    }
    .currency {
      color: #C91717;
    }
  }
  .input {
    height: 51px;
    padding-left: 36px;
    padding-right: 48px;
    &.amount {
      font-family: 'Calibre';
      font-size: 16px;
    }
  }
  .symbol {
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 2;
  }
  .currency {
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    right: 12px;
    top: 16px;
    z-index: 2;
  }
}
</style>
